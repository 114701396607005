const updateLastUpdatedTime = async () => {
  if (document?.querySelector("#last-updated")) {
    const seconds = new Date() - window.loadedTime
    document.querySelector("#last-updated").innerText =
      Math.floor(seconds / 1000) + 1
    await setTimeout(updateLastUpdatedTime, 1000)
  }
}

const setupAndClearLogs = async () => {
  if (document?.querySelector("#logs")) {
    const logsOuterContainer = document.querySelector("#logs-outer")
    logsOuterContainer
      ?.querySelector(`[data-status=loading`)
      ?.classList.toggle("hidden")

    window.loadedTime = new Date()
    await updateLastUpdatedTime()

    const logsContainer = document.querySelector("#logs")
    const response = await fetch(`/api/logs/123`)
    const json = await response.json()
    const lines: HTMLDivElement[] = []
    if (json) {
      json.forEach((item) => {
        const line = document.createElement("div")
        let m = item["message"]
        m = m.replace(/([^\s]*\s){1}/, "")
        line.innerText = m
        lines.push(line)
      })
    }
    logsContainer?.replaceChildren(...lines)
  }
}

export const setupLogs = async () => {
  if (
    document.querySelector("body")?.dataset?.controllerAction ===
    "installations#show"
  ) {
    console.log("Running setupLogs")
    clearInterval(window.logInterval)
    await setupAndClearLogs()
    window.logInterval = window.setInterval(async () => {
      await setupAndClearLogs()
    }, 30 * 1000)
    document.addEventListener("turbo:before-fetch-request", (e) => {
      clearInterval(window.logInterval)
      document.removeEventListener("turbo:load", setupAndClearLogs)
    })
  }
}
